import React from "react";
import { useSelector } from "react-redux";
import { CoradineLogo, LayoutHeader, LayoutHeaderProps, Typography } from "@coradine/web-ui";
import { makeStyles, Link } from "@material-ui/core";
import { ReduxStore } from "../../store/types";
import { HeaderRightContent } from "./components/HeaderRightContent";

const useStyles = makeStyles({
	title: {
		flexGrow: 1,
		marginBottom: 0,
	},
	link: {
		color: "inherit",
	},
});

interface DefaultHeaderProps extends LayoutHeaderProps {
    hideAvatar?: boolean;
}

const DefaultHeader = (props: DefaultHeaderProps) => {
	const { isShifted, hideSidebarToggle, hideAvatar, toggleSidebar } = props;
	const classes = useStyles();
	const loggedIn: boolean = useSelector((state: ReduxStore) => state.user.sessionToken) !== undefined;

	return (
		<LayoutHeader isShifted={isShifted} toggleSidebar={toggleSidebar} hideSidebarToggle={hideSidebarToggle}>
			{loggedIn ? (
				<>
					<Typography variant="h5" className={classes.title}>
						<Link className={classes.link} href={process.env.REACT_APP_SUPPORT}>Get Help</Link>
					</Typography>
					<HeaderRightContent hideAvatar={hideAvatar} />
				</>
			) : <CoradineLogo type="full" style={{ width: "100%" }} />
			}
		</LayoutHeader>
	);
};

export default DefaultHeader;
